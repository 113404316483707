/*Animations*/
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(359deg);
	}
}
.card-actions {
	display: flex;
	justify-content: flex-end;
}
